import Moment from 'moment-timezone';
import {
  Zelo,
  Employee,
  Step,
  InfoStep,
  TextStepInterface,
  VideoStepInterface,
  InvitationStepInterface,
  FeedbackStepInterface,
  ScaleStepInterface,
  MultipleChoiceStepInterface,
  MultipleAnswerStepInterface,
  FileStepInterface,
  StepTypes,
  QuestionStepInterface,
  TestStepInterface,
  ConsentStepInterface,
  TestStartInterface,
  TestInstructionInterface,
  TestSubmissionInterface,
  TestResultsInterface,
  TocStepInterface,
  Channels,
  Whitelabel,
  AttributeTerm,
  ZeloSummary,
  User
} from 'src/types';
import { Observable, pipe } from 'rxjs';
import { delay, startWith, tap } from 'rxjs/operators';
import { UntypedFormGroup, UntypedFormArray } from '@angular/forms';
import { ActiveZeloInterface } from '../dashboard/actions/dashboard.actions';
import { HttpResponse } from '@angular/common/http';
import { ZloChartData, ZloChart } from '../dashboard/models/graph.model';
import { ChartPluginsOptions, ChartOptions } from 'chart.js';
import * as uuid from 'uuid';

export const RETRY_COUNT = 2;

export const CSV_OR_XLSX_PATTERN = /.csv$|.xlsx$/;
export const CSV_PATTERN = /.csv$/;
export const XLSX_PATTERN = /.xlsx$/;

export const iana: string[] = [
  'Pacific/Auckland',
  'Australia/Syndey',
  'Asia/Tokyo',
  'Asia/Manila',
  'Asia/Kolkata',
  'Europe/Helsinki',
  'Europe/Oslo',
  'Europe/London',
  'America/Sao_Paulo',
  'America/Halifax',
  'America/New_York',
  'America/Chicago',
  'America/Denver',
  'America/Los_Angeles',
  'America/Honolulu'
];

export function keyBy<T extends object>(
  collection: Array<T>,
  keyToIndexBy: keyof T
): Channels & { [k: string]: T } {
  const keys = collection.map((value, idx) => ({
    key: (value[keyToIndexBy] as unknown) as string,
    idx
  }));

  const result: { [k: string]: T } = {};
  keys.forEach(({ key, idx }) => {
    if (typeof key !== 'string' && typeof key !== 'number') {
      console.error(
        `The value of the provided key ${key} is not one of 'string' or 'number'`
      );
    }
    result[key] = collection[idx];
  });

  return result;
}

export function generateUUID() {
  return uuid.v4();
}

export const createStep = (stepType: StepTypes, stepNumber: number) => {
  const title = '';
  const description = '';
  const date = new Date();
  const stepId = generateUUID();

  let step: Step = {
    stepId,
    stepNumber,
    title,
    isNew: true,
    isCollapsed: false
  } as Step;
  switch (stepType) {
    case StepTypes.TocStep: {
      step = {
        ...step,
        stepType: StepTypes.TocStep,
        description
      } as TocStepInterface;
      break;
    }
    case StepTypes.TextStep: {
      step = {
        ...step,
        stepType: StepTypes.TextStep,
        message: ''
      } as TextStepInterface;
      break;
    }

    case StepTypes.VideoStep: {
      step = {
        ...step,
        stepType: StepTypes.VideoStep,
        description,
        videoUrl: ''
      } as VideoStepInterface;
      break;
    }

    case StepTypes.InvitationStep: {
      step = {
        ...step,
        stepType: StepTypes.InvitationStep,
        description,
        fromDate: date.toISOString(),
        toDate: null,
        location: {
          name: '',
          latlng: null
        }
      } as InvitationStepInterface;
      break;
    }

    case StepTypes.FileStep: {
      step = {
        ...step,
        stepType: StepTypes.FileStep,
        description,
        files: []
      } as FileStepInterface;
      break;
    }

    case StepTypes.QuestionStep: {
      step = {
        ...step,
        stepType
      } as QuestionStepInterface;
      break;
    }
    case StepTypes.MultipleChoiceStep: {
      step = {
        ...step,
        stepType: StepTypes.MultipleChoiceStep,
        description,
        options: []
      } as MultipleChoiceStepInterface;
      break;
    }
    case StepTypes.MultipleAnswerStep: {
      step = {
        ...step,
        stepType: StepTypes.MultipleAnswerStep,
        description,
        options: [],
        correctFeedback: '',
        incorrectFeedback: ''
      } as MultipleAnswerStepInterface;
      break;
    }
    case StepTypes.ConsentStep: {
      step = {
        ...step,
        stepType: StepTypes.ConsentStep,
        description,
        options: []
      } as ConsentStepInterface;
      break;
    }

    case StepTypes.ScaleStep: {
      step = {
        ...step,
        stepType: StepTypes.ScaleStep,
        description,
        minLabel: '',
        maxLabel: ''
      } as ScaleStepInterface;
      break;
    }

    case StepTypes.FeedbackStep: {
      step = {
        ...step,
        stepType: StepTypes.FeedbackStep,
        description
      } as FeedbackStepInterface;
      break;
    }

    case StepTypes.TestStep: {
      step = {
        ...step,
        stepType: StepTypes.TestStep,
        passPercent: 100,
        tryCount: 3,
        introduction: {
          title: '',
          description: ''
        },
        instructions: {
          title: '',
          description: ''
        },
        submission: {
          title: '',
          description: ''
        },
        completedMessage: {
          passed: '',
          failed: ''
        },
        questions: []
      } as TestStepInterface;
      break;
    }

    default:
      console.error('switch case with no match. This should not happen!');
      return;
  }

  step.isCollapsed = false;

  return step;
};

export const updateZeloStepUtil = (
  step: Step,
  zelo: Zelo,
  stepNumber: number
): Zelo => {
  // info updating zelo info
  if (step.stepType === 'info') {
    const infoStep: InfoStep = step as InfoStep;

    const {
      stepType,
      id,
      isNew,
      response,
      recipients,
      ...zeloProps
    } = infoStep;

    return {
      ...zelo,
      ...zeloProps,
      isValid: false
    };
  }

  const updatedZelo: Zelo = { ...zelo, steps: [...zelo.steps], isValid: false };

  switch (step.stepType) {
    case StepTypes.TocStep: {
      const textStep = step as TocStepInterface;
      const oldStep = updatedZelo.steps[stepNumber] as TocStepInterface;

      updatedZelo.steps[stepNumber] = Object.assign(
        oldStep,
        textStep
      ) as TocStepInterface;

      break;
    }
    case StepTypes.TextStep: {
      const textStep = step as TextStepInterface;
      const oldStep = updatedZelo.steps[stepNumber] as TextStepInterface;

      updatedZelo.steps[stepNumber] = Object.assign(
        oldStep,
        textStep
      ) as TextStepInterface;

      break;
    }
    case StepTypes.VideoStep: {
      const videoStep = step as VideoStepInterface;
      const oldStep = updatedZelo.steps[stepNumber] as VideoStepInterface;
      updatedZelo.steps[stepNumber] = Object.assign(
        oldStep,
        videoStep
      ) as VideoStepInterface;

      break;
    }
    case StepTypes.InvitationStep: {
      const invitationStep = step as InvitationStepInterface;
      const oldStep = updatedZelo.steps[stepNumber] as InvitationStepInterface;
      updatedZelo.steps[stepNumber] = Object.assign(
        oldStep,
        invitationStep
      ) as InvitationStepInterface;

      break;
    }
    case StepTypes.FileStep: {
      const fileStep = step as FileStepInterface;
      const oldStep = updatedZelo.steps[stepNumber] as FileStepInterface;
      updatedZelo.steps[stepNumber] = Object.assign(
        oldStep,
        fileStep
      ) as FileStepInterface;

      break;
    }
    case StepTypes.MultipleChoiceStep: {
      const multipleChoiceStep = step as MultipleChoiceStepInterface;
      const oldStep = updatedZelo.steps[
        stepNumber
      ] as MultipleChoiceStepInterface;
      updatedZelo.steps[stepNumber] = Object.assign(
        oldStep,
        multipleChoiceStep
      ) as MultipleChoiceStepInterface;

      break;
    }
    case StepTypes.MultipleAnswerStep: {
      const multipleAnswerStep = step as MultipleAnswerStepInterface;
      const oldStep = updatedZelo.steps[
        stepNumber
      ] as MultipleAnswerStepInterface;
      updatedZelo.steps[stepNumber] = Object.assign(
        oldStep,
        multipleAnswerStep
      ) as MultipleAnswerStepInterface;

      break;
    }
    case StepTypes.ConsentStep: {
      const consentStep = step as ConsentStepInterface;
      const oldStep = updatedZelo.steps[stepNumber] as ConsentStepInterface;
      updatedZelo.steps[stepNumber] = Object.assign(
        oldStep,
        consentStep
      ) as ConsentStepInterface;

      break;
    }
    case StepTypes.ScaleStep: {
      const scaleStep = step as ScaleStepInterface;
      const oldStep = updatedZelo.steps[stepNumber] as ScaleStepInterface;
      updatedZelo.steps[stepNumber] = Object.assign(
        oldStep,
        scaleStep
      ) as ScaleStepInterface;
      break;
    }
    case StepTypes.FeedbackStep: {
      const feedbackStep = step as FeedbackStepInterface;
      const oldStep = updatedZelo.steps[stepNumber] as FeedbackStepInterface;
      updatedZelo.steps[stepNumber] = Object.assign(
        oldStep,
        feedbackStep
      ) as FeedbackStepInterface;
      break;
    }
    case StepTypes.TestStep: {
      const testStep = step as TestStepInterface;
      const oldStep = updatedZelo.steps[stepNumber] as TestStepInterface;
      updatedZelo.steps[stepNumber] = Object.assign(
        oldStep,
        testStep
      ) as TestStepInterface;
      break;
    }
    default:
      console.error('switch case with no match. This should not happen!');
  }

  return updatedZelo;
};

export const excludeFirstArrayFromSecondArray = <T, K extends keyof T>(
  excludeList: T[] = [],
  fullList: T[] = [],
  filterKey: K
): T[] => {
  return fullList.filter(
    (element: T) =>
      !excludeList.some((item) => item[filterKey] === element[filterKey])
  );
};

export const excludeFirstArrayFromSecondArrayUsingSet = <T>(
  excludeList: T[] = [],
  fullList: T[] = []
): T[] => {
  const excludeListSet = new Set(excludeList);
  return fullList.filter((user) => !excludeListSet.has(user));
};

export const excludeEmployeeArrayFromArray = (
  excludeList: Employee[] = [],
  fullList: Employee[] = []
) => {
  return fullList.filter(
    (user) => !excludeList.some((us) => us.id === user.id)
  );
};

export const filterIncludeRecipients = (
  search: string,
  employeeList: Employee[] = []
) => {
  if (!search) {
    return employeeList;
  }

  if (typeof search !== 'string') {
    return employeeList;
  }

  return employeeList.filter((employee) => {
    const fullname = `${employee.firstname} ${employee.lastname}`;
    return fullname.toLocaleLowerCase().includes(search.toLocaleLowerCase());
  });
};

export const formatTestStepForResponse = (steps: Step[]) => {
  const newSteps: Step[] = [];

  if (!steps) {
    return newSteps;
  }

  steps.forEach((step) => {
    if (step.stepType === 'test') {
      const testStep = step as TestStepInterface;
      newSteps.push(
        {
          stepType: StepTypes.TestStart,
          isNew: false,
          message: testStep.introduction.description,
          tryCount: 0,
          maxTryCount: testStep.tryCount,
          passPercent: testStep.passPercent,
          title: testStep.introduction.title
        } as TestStartInterface,
        {
          stepType: StepTypes.TestInstruction,
          isNew: false,
          message: testStep.instructions.description,
          title: testStep.instructions.title
        } as TestInstructionInterface,
        ...testStep.questions.map((question) => {
          return {
            ...question,
            stepType: StepTypes.TestQuestion,
            isNew: false
          };
        }),
        {
          stepType: StepTypes.TestSubmission,
          message: testStep.submission.description,
          title: testStep.submission.title,
          isNew: false
        } as TestSubmissionInterface,
        {
          stepType: StepTypes.TestResults,
          passed: false,
          isNew: false,
          score: 0,
          title: testStep.title
        } as TestResultsInterface
      );
      return;
    }

    newSteps.push(step);
  });

  return newSteps;
};

const count = (values: string[]) =>
  values.reduce<string | any>(
    (a, b) => Object.assign(a, { [b]: (a[b] || 0) + 1 }),
    {}
  );

const duplicates = (dict: any) => Object.keys(dict).filter((a) => dict[a] > 1);

// Checks for duplicate strings in an array and returns how many times each
// string occurs along with an array of the duplicate strings
export const checkDuplicates = (
  toCheck: string[]
): { count: { [key: string]: number }; duplicates: string[] } => {
  return { count: count(toCheck), duplicates: duplicates(count(toCheck)) };
};

// custom operator

export const delayAndStartWith = (
  delayValue: number = 0,
  startValue: string = ''
) => {
  return (src: Observable<any>) =>
    src.pipe(delay(delayValue), startWith(startValue));
};

export const autoCompleteEmployees = (
  search: string,
  excludeList: Employee[],
  fullList: Employee[]
) => {
  const filt = excludeEmployeeArrayFromArray(excludeList, fullList);
  return filterIncludeRecipients(search, filt);
};

export const getObjectValues = (obj: any, accum: string[]) => {
  for (const key in obj) {
    if (typeof obj[key] === 'object') {
      getObjectValues(obj[key], accum);
    } else {
      if (key !== 'id' && obj[key]) {
        accum.push(obj[key].toString());
      }
    }
  }
};

const recipientsFromRecipientIds = (
  recipientIds: string[] = [],
  employees: Employee[] = []
) => {
  return recipientIds
    .map((recId) => {
      const [emp] = employees.filter((employee) => recId === employee.id);
      return emp || null;
    })
    .filter((emp) => !!emp);
};

export const enrichActiveZeloListWithEmployees = (
  list: ActiveZeloInterface[],
  employees: Employee[]
) => {
  return list.map((activeZelo) => {
    // enrich recipientIds to make them employees
    activeZelo.recipients = recipientsFromRecipientIds(
      activeZelo.recipientIds,
      employees
    );
    activeZelo.notOpenedRecipients = recipientsFromRecipientIds(
      activeZelo.notOpenedRecipientIds,
      employees
    );
    activeZelo.notCompletedRecipients = recipientsFromRecipientIds(
      activeZelo.notCompletedRecipientIds,
      employees
    );

    return activeZelo;
  });
};

export function filterPredicate<T>(item: T, term: string = ''): boolean {
  if (!term) {
    return true;
  }

  const ListOfSearchTerms = term.toLocaleLowerCase().trim().split(' ');

  const values: string[] = [];
  getObjectValues(item, values); // values is placeholder that will end up with the object values

  return ListOfSearchTerms.every(
    (searchTerm) =>
      values
        .map((val) => val.toLocaleLowerCase())
        .filter((val) => val.includes(searchTerm)).length > 0
  );
}

export function xlsxOperator(filename: string = 'file') {
  return pipe(
    tap((response: HttpResponse<{ report: string }>) => {
      const linkElement = document.createElement('a');
      linkElement.setAttribute(
        'href',
        `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${response.body.report}`
      );

      const contentDispositionHeader = `attachement; filename="${filename}.xlsx"`;
      let result = contentDispositionHeader.split(';')[1].trim().split('=')[1];
      result = result.replace(/"/g, '');

      linkElement.setAttribute('download', result);

      const clickEvent = new MouseEvent('click', {
        view: window,
        bubbles: true,
        cancelable: false
      });

      linkElement.dispatchEvent(clickEvent);
    })
  );
}

export function getZeloApplicationError(error: any) {
  if (error && error.error) {
    try {
      const appError = JSON.parse(error.error.message);
      if (typeof appError === 'object') {
        return {
          isApplicationError: true,
          ...appError
        };
      }
    } catch (error) {
      return error;
    }
  }

  return error;
}

export function getAllFormErrors(
  form: UntypedFormGroup | UntypedFormArray
): { [key: string]: any } | null {
  if (!form) {
    return null;
  }

  let hasError = false;
  if (form.errors) {
    hasError = true;
  }

  const result = Object.keys(form.controls).reduce((acc, key) => {
    const control = form.get(key);
    const errors =
      control instanceof UntypedFormGroup || control instanceof UntypedFormArray
        ? getAllFormErrors(control)
        : control.errors;
    if (errors) {
      acc[key] = errors;
      hasError = true;
    }
    return acc;
  }, {} as { [key: string]: any });
  return hasError ? result : null;
}

export function getRootFormGroup(
  group: UntypedFormGroup | UntypedFormArray
): UntypedFormGroup | UntypedFormArray {
  if (!group) {
    return group;
  }

  const parent: UntypedFormGroup | UntypedFormArray = group.parent;

  if (parent.parent) {
    return getRootFormGroup(parent);
  }

  return parent;
}

export function updateFormTreeValueAndValidity(
  form: UntypedFormGroup | UntypedFormArray
) {
  if (!form) {
    return;
  }

  form.updateValueAndValidity();
  Object.values(form.controls).forEach((control) => {
    control instanceof UntypedFormGroup || control instanceof UntypedFormArray
      ? updateFormTreeValueAndValidity(control)
      : control.updateValueAndValidity();
  });
}

const colors: { [key: string]: string } = {
  blue: '#4e3f9e',
  green: 'rgb(117, 204, 180)',
  yellow: 'rgb(255, 203, 68)',
  lightblue: 'rgb(86, 200, 236)'
};

const chartColors: { [key: string]: string } = {
  average: colors.blue,
  desktop: colors.green,
  mobile: colors.yellow,
  windows: colors.blue,
  macos: colors.green,
  android: colors.lightBlue,
  ios: colors.yellow,
  channel: colors.yellow
};

export const withDefaultStyling = (data: any) => {
  const dataLabel = data.label.toLowerCase();
  const color: string | string[] = Object.keys(chartColors).includes(dataLabel)
    ? chartColors[dataLabel]
    : Object.values(colors);

  // Style dataset labels to capital first letter (legend text)

  data.label = data.label.charAt(0).toUpperCase() + data.label.slice(1);

  if (data.label.toLowerCase() === 'ios') {
    data.label = 'iOS';
  }

  return {
    ...data,
    fill: false,
    backgroundColor: color,
    borderColor: color,
    pointBorderColor: 'rgba(0, 0, 0, 0)',
    pointBackgroundColor: 'rgba(0, 0, 0, 0)',
    pointHoverBackgroundColor: color,
    pointHoverBorderColor: color,
    pointHoverRadius: 8
  };
};

export const addBarChartConfig = (zloChartData: ZloChartData, id: string) => {
  // Style y axis ticks with all capital letters
  if (zloChartData.labels) {
    zloChartData.labels = zloChartData.labels.map((label) => {
      if (typeof label === 'string') {
        return label.toUpperCase();
      }
      return label;
    });
  }

  return {
    id,
    chartType: 'bar',
    chartData: zloChartData
  };
};

export const addLineChartConfig = (zloChartData: ZloChartData, id: string) => {
  return {
    id,
    chartType: 'line',
    chartData: zloChartData
  };
};

export const addPieChartConfig = (zloChartData: ZloChartData, id: string) => {
  return {
    id,
    chartType: 'pie',
    chartData: zloChartData
  };
};

export const addHorizontalBarChartConfig = (
  zloChartdata: ZloChartData,
  id: string
) => {
  // Style y axis ticks with all capital letters
  if (zloChartdata.labels) {
    zloChartdata.labels = zloChartdata.labels.map((label) => {
      return label.toUpperCase();
    });
  }

  return {
    id,
    chartType: 'horizontalBar',
    chartData: zloChartdata
  };
};

export const addDataSetSpecificStyling = (
  chart: ZloChart,
  options?: ChartOptions | ChartPluginsOptions
) => {
  return {
    ...chart,
    chartData: {
      ...chart.chartData,
      datasets: chart.chartData.datasets
        ? chart.chartData.datasets.map((dataSet) => {
            let result;
            const label = dataSet.label
              ? dataSet.label.toLowerCase()
              : undefined;
            switch (label) {
              case 'average':
                result = {
                  ...dataSet,
                  label: 'average',
                  type: 'line'
                };
                break;

              case 'desktop':
                result = {
                  ...dataSet,
                  label: 'desktop',
                  backgroundColor: 'rgb(117, 204, 180)'
                };
                break;

              case 'mobile':
                result = {
                  ...dataSet,
                  label: 'mobile'
                };
                break;

              case 'windows':
                result = {
                  ...dataSet,
                  label: 'windows'
                };
                break;

              case 'macos':
                result = {
                  ...dataSet,
                  label: 'macos'
                };
                break;

              case 'android':
                result = {
                  ...dataSet,
                  label: 'android'
                };
                break;

              case 'ios':
                result = {
                  ...dataSet,
                  label: 'ios'
                };
                break;

              case 'language':
                result = {
                  ...dataSet,
                  label: 'language'
                };
                break;

              case 'channel':
                result = {
                  ...dataSet,
                  label: 'channel'
                };
                break;

              default:
                result = {
                  ...dataSet
                };
                break;
            }
            result = options ? { ...result, ...options } : result;
            return withDefaultStyling(result);
          })
        : []
    }
  };
};

// Takes two parameters, an array of strings and a 'replacements' object.
// string array is presumed to contained placeholders signified by percetange signs %PLACEHOLDER_TEXT%
// In the replacements object, define matches between placeholder values and hydrated value. ex: {'%PLACEHOLDER%': newObj.newValue}
export const replacePlaceholdersInString = (
  replacements: { [key: string]: string },
  strings: string[]
) => {
  return strings.map((value) => {
    return (value as string).replace(/%\w+%/g, (all) => {
      return replacements[all] || all;
    });
  });
};

// From an array of strings - remove strings containing the word 'UNDEFINED'
export const generateSummaryStringsWithCleanData = (strings: string[]) => {
  return strings
    .map((summaryString) => {
      const match = summaryString.indexOf('UNDEFINED') > -1;
      if (match) {
        const isPart =
          summaryString.includes(' and') || summaryString.includes(' or');
        const indexOfBindingWord =
          summaryString.indexOf(' and') || summaryString.indexOf(' or');

        if (isPart) {
          const latterPartOfSentence = summaryString.slice(
            indexOfBindingWord,
            summaryString.length
          );

          const firstPartOfSentence = summaryString.slice(
            0,
            indexOfBindingWord
          );

          if (
            latterPartOfSentence.indexOf('UNDEFINED') > -1 &&
            firstPartOfSentence.indexOf('UNDEFINED') > -1
          ) {
            return;
          }

          if (latterPartOfSentence.indexOf('UNDEFINED') > -1) {
            return `<p>${firstPartOfSentence}</p>`;
          }
        }

        return;
      } else {
        return `<p>${summaryString}</p>`;
      }
    })
    .filter((item) => item);
};

export const createSummaryStrings = (replacements: any, strings: string[]) => {
  const hydratedStrings = replacePlaceholdersInString(replacements, strings);

  const hydratedStringWithValues = generateSummaryStringsWithCleanData(
    hydratedStrings
  );

  return hydratedStringWithValues;
};

export interface TranslationStringArray {
  [key: string]: any;
}

export const compare = (a: string, b: string, isAsc: boolean) => {
  return (
    ((a || '').toLowerCase() < (b || '').toLowerCase() ? -1 : 1) *
    (isAsc ? 1 : -1)
  );
};

/* eslint-disable no-magic-numbers */
export const mapLayers = [
  {
    id: 'topLocations-heat',
    type: 'heatmap',
    source: 'topLocations',
    maxzoom: 9,
    paint: {
      // Increase the heatmap weight based on frequency and property count (contry count)
      'heatmap-weight': [
        'interpolate',
        ['linear'],
        ['get', 'count'],
        0,
        0,
        6,
        1
      ],
      // Increase the heatmap color weight weight by zoom level
      // heatmap-intensity is a multiplier on top of heatmap-weight
      'heatmap-intensity': ['interpolate', ['linear'], ['zoom'], 0, 1, 9, 3],
      // Color ramp for heatmap.  Domain is 0 (low) to 1 (high).
      // Begin color ramp at 0-stop with a 0-transparancy color
      // to create a blur-like effect.
      'heatmap-color': [
        'interpolate',
        ['linear'],
        ['heatmap-density'],
        0,
        'rgba(136, 240, 178, 0)',
        0.2,
        'rgb(86, 200, 236)',
        0.4,
        'rgb(186, 233, 247)',
        0.6,
        'rgb(255, 233, 179)',
        0.8,
        'rgb(255, 203, 68)',
        1,
        'rgb(225, 68, 87)'
      ],
      // Adjust the heatmap radius by zoom level
      'heatmap-radius': ['interpolate', ['linear'], ['zoom'], 21, 22, 23, 24],
      // Transition from heatmap to circle layer by zoom level
      'heatmap-opacity': ['interpolate', ['linear'], ['zoom'], 7, 1, 9, 0]
    }
  },
  {
    id: 'topLocations-point',
    type: 'circle',
    source: 'topLocations',
    minzoom: 7,
    paint: {
      // Size circle radius by country count and zoom level
      'circle-radius': [
        'interpolate',
        ['linear'],
        ['zoom'],
        7,
        ['interpolate', ['linear'], ['get', 'count'], 1, 1, 6, 4],
        16,
        ['interpolate', ['linear'], ['get', 'count'], 1, 5, 6, 50]
      ],
      // Color circle by country count
      'circle-color': [
        'interpolate',
        ['linear'],
        ['get', 'count'],
        1,
        'rgba(33,102,172,0)',
        2,
        'rgb(103,169,207)',
        3,
        'rgb(209,229,240)',
        4,
        'rgb(253,219,199)',
        5,
        'rgb(239,138,98)',
        6,
        'rgb(178,24,43)'
      ],
      'circle-stroke-color': 'white',
      'circle-stroke-width': 1,
      // Transition from heatmap to circle layer by zoom level
      'circle-opacity': ['interpolate', ['linear'], ['zoom'], 7, 0, 8, 1]
    }
  }
];

export const hexToRGB = (hex: string, alpha: number) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  if (alpha) {
    return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')';
  } else {
    return 'rgb(' + r + ', ' + g + ', ' + b + ')';
  }
};

export const generateBackground = (
  pageBackground: Whitelabel['colorPalette']['pageBackground']
) => {
  const color0 = hexToRGB(
    pageBackground?.[0] || '#80dff7',
    pageBackground?.[0] ? 1 : 0.4
  );
  const color1 = hexToRGB(
    pageBackground?.[1] || '#80dff7',
    pageBackground?.[0] ? 1 : 0.4
  );
  const color2 = hexToRGB(
    pageBackground?.[1] || '#08f0af',
    pageBackground?.[1] ? 1 : 0.4
  );
  const color3 = hexToRGB(
    pageBackground?.[2] || '#dfffc9',
    pageBackground?.[2] ? 1 : 0.4
  );

  // example output `radial-gradient(circle at top left, rgba(78, 63, 158, 0.2) 0%, rgba(128, 223, 247, 0.2) 44.43%, rgba(8, 240, 175, 0.2) 61.69%, rgba(223, 255, 201, 0.2) 100%)`
  const constPageContainerStyles = {
    'background-image': `radial-gradient(circle at top left,${color0} 0%,${color1} 44.43%,${color2} 61.69%,${color3} 100%)`
  };

  return constPageContainerStyles;
};

export const capitalizeFirstCharacter = (stringToProcess: string) => {
  if (stringToProcess.toLowerCase() === 'sms')
    return stringToProcess.toUpperCase();
  return (
    stringToProcess.charAt(0).toLocaleUpperCase() +
    stringToProcess.slice(1).toLocaleLowerCase()
  );
};

export const makeTitleCase = (stringToProcess: string) => {
  const strLowerCase = stringToProcess.toLowerCase();

  if (strLowerCase === 'sms' || !strLowerCase)
    return stringToProcess.toUpperCase();

  const wordArr = strLowerCase.split(' ').map((currentValue) => {
    return currentValue[0].toUpperCase() + currentValue.substring(1);
  });

  return wordArr.join(' ');
};

export const findAttributes = (terms: any, attributeName: string) => {
  return terms
    ?.filter(
      (term: AttributeTerm) =>
        term.attributeName.toLowerCase() === attributeName.toLowerCase()
    )
    .flatMap((term: AttributeTerm) => term.name)
    .toString();
};

export const makeAttributeTerms = (user: any) => {
  if (!user.importedAttributeTerms) {
    return [];
  }

  const attributeTerms = [];

  for (let i = 0; i < user.importedAttributeTerms.length; i++) {
    attributeTerms.push({
      attributeName: user.importedAttributeNames[i],
      name: user.importedAttributeTerms[i]
    });
  }

  return attributeTerms;
};

// Copies a string to the clipboard. Must be called from within an
// event handler such as click. May return false if it failed, but
// this is not always possible. Browser support for Chrome 43+,
// Firefox 42+, Safari 10+, Edge and Internet Explorer 10+.
// Internet Explorer: The clipboard feature may be disabled by
// an administrator. By default a prompt is shown the first
// time the clipboard is used (per session).
export const copyToClipboard = (text: any) => {
  const clipboard = window.navigator.clipboard;
  if (clipboard && clipboard.writeText) {
    // Internet Explorer-specific code path to prevent textarea being shown while dialog is visible.
    return clipboard.writeText(String(text));
  } else if (
    document.queryCommandSupported &&
    document.queryCommandSupported('copy')
  ) {
    const textarea = document.createElement('textarea');
    textarea.textContent = text;
    textarea.style.position = 'fixed'; // Prevent scrolling to bottom of page in Microsoft Edge.
    document.body.appendChild(textarea);
    textarea.select();
    try {
      return document.execCommand('copy'); // Security exception may be thrown by some browsers.
    } catch (ex) {
      console.warn('Copy to clipboard failed.', ex);
      return false;
    } finally {
      document.body.removeChild(textarea);
    }
  }
};

export const isValidDomain = (domain: string) => {
  const validDomainRegex = /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/;

  if (!validDomainRegex.test(domain)) {
    if (domain !== '') {
      console.log('Domain not valid');
    }
    return false;
  }

  return true;
};

export const parseInTimezone = (tz: string, dt: string = null) => {
  if (+tz[0]) {
    throw new Error('Check order of args to parseInTimezone');
  }

  if (!dt) {
    return Moment.tz(tz); // now
  }

  dt = dt.length === 10 ? dt + ' 00:00' : dt;
  dt = dt.replace('T', ' ').split('+')[0];
  return Moment.tz(dt, 'YYYY-MM-DD HH:mm', tz);
};

export const deriveZeloStatus = (zelo: Zelo | ZeloSummary) => {
  if (zelo.isArchived) {
    return 'archived';
  }

  const jobs = zelo.distributorJobs;
  const job = jobs && jobs.find((j) => j.jobType === 'sendZelos');

  if (!job) {
    return zelo.dateSent ? 'sent' : 'draft';
  }

  if (job.size > 0 && job.size <= job.counter) {
    job.size = Math.max(job.size, job.counter);
    return 'sent';
  }

  if (!job.startAt) {
    return 'canceled';
  }

  const ts = job.startAt;
  const tz = job.startAtTimezone || 'UTC';
  const startAtMoment = parseInTimezone(tz, ts);

  if (!startAtMoment?.isValid()) {
    return 'canceled';
  }

  if (startAtMoment && startAtMoment > Moment()) {
    return job.suspended ? 'suspended' : 'scheduled';
  }

  return job.suspended ? (job.counter ? 'paused' : 'suspended') : 'sending';
};

export const getFromTeams = (endpoint: string, token: string) => {
  const url = `https://graph.microsoft.com/beta/${endpoint}`;
  const method = 'GET';
  const headers = new Headers();
  const bearer = `Bearer ${token}`;
  headers.append('Authorization', bearer);
  return fetch(url, { method, headers }).then((r) => r.json());
};

export const postToTeams = (endpoint: string, token: string, data: any) => {
  const isBlob = data instanceof Blob;
  const contentType = isBlob ? 'application/zip' : 'application/json';
  const body = isBlob ? data : JSON.stringify(data);
  const url = `https://graph.microsoft.com/beta/${endpoint}`;
  const method = 'POST';
  const headers = new Headers();
  const bearer = `Bearer ${token}`;
  headers.append('Authorization', bearer);
  headers.append('Content-Type', contentType);
  return fetch(url, { method, headers, body }).then((r) => r.json());
};

export const isTestUser = (user: User) => {
  if (!user) return false;
  return (
    user.email.endsWith('.test') ||
    user.email.endsWith('mailosaur.net') ||
    user.organization?.e2e
  );
};
