import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { Store } from '@ngrx/store';
import { OverviewLink } from '../actions/auth.actions';
import { UserValidators } from '../user.util';
import { ActivatedRoute, Params, Router } from '@angular/router';

@Component({
  selector: 'zlo-overview-link-prompt',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './overview-link-prompt.component.html',
  styleUrls: ['./login-page.component.scss']
})
export class OverviewLinkPromptComponent implements OnInit {
  form: UntypedFormGroup;

  constructor(
    private fb: UntypedFormBuilder,
    private store: Store<any>,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    this.form = this.fb.group({
      email: ['', UserValidators.requiredEmail]
    });

    this.activatedRoute.queryParams.subscribe((params: Params) => {
      if (params.email) {
        this.form.get('email').setValue(params.email);
        this.router.navigate([], {
          queryParams: {
            email: null
          },
          queryParamsHandling: 'merge',
          replaceUrl: true
        });
      }
    });
  }

  submit() {
    if (this.form.valid) {
      this.store.dispatch(
        OverviewLink({ payload: this.form.get('email').value.trim() })
      );
    }
  }
}
