<div class="grid-x align-center text-center">
  <div class="padding-m margin-bl cell">
    <zlo-icon name="zelo-logo" width="100px"></zlo-icon>
  </div>

  <div class="cell">
    <form [formGroup]="form" #ngForm="ngForm" (ngSubmit)="submit()">
      <div class="login-form-container shadow-2 grid-x">
        <div class="cell">
          <h4 class="login-title">{{ 'auth.login.header' | translate }}</h4>
        </div>
        <div class="cell text-left">
          <label>
            {{ 'auth.emailLabel' | translate }}
            <span
              class="error-list"
              *ngIf="ngForm.submitted && form.get('email').hasError('required')"
            >
              {{ 'auth.errors.isRequired' | translate }}</span
            >
            <span
              class="error-list"
              *ngIf="ngForm.submitted && form.get('email').hasError('email')"
            >
              {{ 'auth.errors.isInvalid' | translate }}</span
            >
            <input
              type="text"
              [placeholder]="'auth.emailPlaceholder' | translate"
              formControlName="email"
              autocomplete="email"
              autofocus="true"
            />
          </label>
        </div>
        <div class="cell text-left">
          <label>
            {{ 'auth.login.passwordLabel' | translate }}
            <span
              class="error-list"
              *ngIf="
                ngForm.submitted && form.get('password').hasError('required')
              "
            >
              {{ 'shared.errors.isRequired' | translate }}</span
            >
            <span
              class="error-list"
              *ngIf="
                ngForm.submitted && form.get('password').hasError('minlength')
              "
            >
              {{ 'auth.errors.passwordMinLength' | translate }}</span
            >
            <input
              type="password"
              [placeholder]="'auth.login.passwordPlaceholder' | translate"
              formControlName="password"
              autocomplete="current-password"
            />
            <span class="error-list" *ngIf="ngForm.submitted && otpSendLimit">
              {{ 'auth.errors.otpRateLimit' | translate }}</span
            >
          </label>
        </div>
        <div *ngIf="shouldShowOTP()" class="cell text-left">
          <label>
            {{ getOtpLabel() }}
            <span
              class="error-list"
              *ngIf="ngForm.submitted && form.get('otp').hasError('required')"
            >
              {{ 'shared.errors.isRequired' | translate }}</span
            >
            <input
              type="text"
              [placeholder]="'auth.login.otpPlaceholder' | translate"
              formControlName="otp"
            />
            <span class="error-list" *ngIf="ngForm.submitted && otpInvalid">
              {{ 'auth.errors.otpInvalid' | translate }}</span
            >
            <span class="error-list" *ngIf="ngForm.submitted && otpVerifyLimit">
              {{ 'auth.errors.otpRateLimit' | translate }}</span
            >
            <span class="error-list" *ngIf="ngForm.submitted && otpTimeout">
              {{ 'auth.errors.otpTimeout' | translate }}</span
            >
            <span
              class="error-list"
              *ngIf="form.get('otp').hasError('pattern')"
            >
              {{ 'auth.errors.otpPattern' | translate }}</span
            >
          </label>
        </div>
        <div class="cell">
          <button
            class="button small expanded login-button"
            type="submit"
            mat-button
          >
            {{ 'auth.login.button' | translate }}
          </button>
        </div>
      </div>
    </form>
  </div>
  <div class="cell login-nav-link">
    <div class="grid-y">
      <div class="cell shrink">
        <a routerLink="/auth/sso" [queryParams]="{ email: form.value.email }">
          Sign in with SSO
        </a>
      </div>
      <div class="cell shrink">
        <a
          routerLink="/auth/forgot"
          [queryParams]="{ email: form.value.email }"
        >
          {{ 'auth.links.forgotPassword' | translate }}
        </a>
      </div>
      <div class="cell shrink">
        <a
          routerLink="/auth/endusers"
          [queryParams]="{ email: form.value.email }"
        >
          {{ 'auth.links.sendOverview' | translate }}
        </a>
      </div>
      <div class="cell shrink margin-tm">
        <a href="https://www.zeloapp.com" target="_blank" rel="noopener">
          {{ 'auth.links.aboutZelo' | translate }}
        </a>
      </div>
    </div>
  </div>
</div>
