import {
  Component,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy
} from '@angular/core';
import { UntypedFormGroup, FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Authenticate } from 'src/types';
import { UserValidators } from '../user.util';
import { ActivatedRoute, Params, Router } from '@angular/router';

@Component({
  selector: 'zlo-login-form',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './login-form.component.html',
  styleUrls: ['./../containers/login-page.component.scss']
})
export class LoginFormComponent {
  @Input()
  set pending(isPending: boolean) {
    if (isPending) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }

  @Input() otpChannel: string = null;
  @Input() otpInvalid: boolean = false;
  @Input() otpSendLimit: boolean = false;
  @Input() otpVerifyLimit: boolean = false;
  @Input() otpTimeout: boolean = false;

  @Output()
  submitted = new EventEmitter<Authenticate>();

  form: UntypedFormGroup = new UntypedFormGroup({
    email: new FormControl<string>('', UserValidators.requiredEmail),
    password: new FormControl<string>('', UserValidators.requiredPassword),
    otp: new FormControl<string>('', UserValidators.otpValidator)
  });

  constructor(
    public translate: TranslateService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      if (params.email) {
        this.form.get('email').setValue(params.email);
        this.router.navigate([], {
          queryParams: {
            email: null
          },
          queryParamsHandling: 'merge',
          replaceUrl: true
        });
      }
    });
  }

  shouldShowOTP() {
    const shoudShowOTP = this.otpChannel !== null;

    if (shoudShowOTP) {
      this.form.get('email').disable();
      this.form.get('password').disable();
    }

    return shoudShowOTP;
  }

  getOtpLabel() {
    const ch = this.otpChannel;
    if (!ch) return '';
    const channel = 'Email'; //this.translate.instant(`testChannel.${ch}`);
    return this.translate.instant('auth.login.otpLabel', { channel });
  }

  submit() {
    if (this.form.valid) {
      const formValue = this.form.getRawValue();
      formValue.email = formValue.email.trim();
      formValue.otp = formValue.otp.trim();
      this.submitted.emit(formValue as Authenticate);
    }
  }
}
