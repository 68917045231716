import { createAction, props } from '@ngrx/store';
import {
  OrganizationStatisticsResponse,
  User,
  Channel,
  OrganizationSettings,
  Whitelabel,
  OrgAttr
} from 'src/types';
import {
  AuthenticateDomainResponse,
  VerifyDomainResponse
} from '../services/domains.service';

enum SettingsActionTypes {
  OrganizationStatistics = '[Settings] Organization Statistics',
  OrganizationStatisticsSuccess = '[Settings] Organization Statistics Success',
  OrganizationStatisticsFailure = '[Settings] Organization Statistics Failure',

  UpdateCurrentUser = '[Settings] Update CurrentUser',
  UpdateCurrentUserSuccess = '[Settings] Update Current User Success',
  UpdateCurrentUserFailure = '[Settings] Update Current User Failure',

  CreateIntegrationCredentials = '[Settings] Create Integration Credentials',
  CreateIntegrationCredentialsSuccess = '[Settings] Create Integration Credentials Success',
  CreateIntegrationCredentialsFailure = '[Settings] Create Integration Credentials Failure',

  UpdateIntegration = '[Settings] Update Integration ',
  UpdateIntegrationSuccess = '[Settings] Update Integration  Success',
  UpdateIntegrationFailure = '[Settings] Update Integration  Failure',

  UpdateOrganizationPreferences = '[Settings] Update Organization Preferences',
  UpdateOrganizationPreferencesSuccess = '[Settings] Update Organization Preferences Success',

  LoadOrganizationWhitelabel = '[Settings] Load Organization Whitelabel',
  LoadOrganizationWhitelabelSuccess = '[Settings] Load Organization Whitelabel Success',
  LoadOrganizationWhitelabelFailure = '[Settings] Load Organization Whitelabel Failure',

  LoadAuthenticatedDomains = '[Settings] Load Organization Authenticated Domains',
  LoadAuthenticatedDomainsSuccess = '[Settings] Load Organization Authenticated Domains Success',
  LoadAuthenticatedDomainsFailure = '[Settings] Load Organization Authenitcated Domains Failure',

  AddAuthenticatedDomain = '[Settings] Add authenticated domain',
  AddAuthenticatedDomainSuccess = '[Settings] Add authenticated domain Success',
  AddAuthenticatedDomainFailure = '[Settings] Add authenticated domain Failure',

  VerifyAuthenticatedDomain = '[Settings] Verify authenticated domain',
  VerifyAuthenticatedDomainSuccess = '[Settings] Verify authenticated domain Success',
  VerifyAuthenticatedDomainFailure = '[Settings] Verify authenticated domain Failure',

  DeleteAuthenticatedDomain = '[Settings] Delete authenticated domain',
  DeleteAuthenticatedDomainSuccess = '[Settings] Delete authenticated domain Success',
  DeleteAuthenticatedDomainFailure = '[Settings] Delete authenticated domain Failure',

  SendDnsDetailsToCoworker = '[Settings] Send Dns Details To Coworker',
  SendDnsDetailsToCoworkerSuccess = '[Settings] Send Dns Details To CoworkerSuccess',
  SendDnsDetailsToCoworkerFailure = '[Settings] Send Dns Details To Coworker Failure',

  SaveOrgAttr = '[Settings] Save OrgAttr',
  SaveOrgAttrSuccess = '[Settings] Save OrgAttr',
  SaveOrgAttrFailure = '[Settings] Save OrgAttr',

  SaveRosterColumns = '[Settings] Save Muster Columns For User'
}

/* -------- Organization Statistics -------- */

export const organizationStatistics = createAction(
  SettingsActionTypes.OrganizationStatistics
);

export const organizationStatisticsSuccess = createAction(
  SettingsActionTypes.OrganizationStatisticsSuccess,
  props<OrganizationStatisticsResponse>()
);

export const organizationStatisticsFailure = createAction(
  SettingsActionTypes.OrganizationStatisticsFailure,
  props<any>()
);

/* -------- User -------- */
export const updateCurrentUser = createAction(
  SettingsActionTypes.UpdateCurrentUser,
  props<User>()
);

export const updateCurrentUserSuccess = createAction(
  SettingsActionTypes.UpdateCurrentUserSuccess,
  props<User>()
);

export const updateCurrentUserFailure = createAction(
  SettingsActionTypes.UpdateCurrentUserFailure,
  props<any>()
);

/* -------- Integration Credentials -------- */
export const createIntegrationCredentials = createAction(
  SettingsActionTypes.CreateIntegrationCredentials,
  props<{
    channel: Omit<Channel, 'id' | 'createdAt' | 'updatedAt'>;
    code?: string;
    organizationId: string;
  }>()
);

export const createIntegrationCredentialsSuccess = createAction(
  SettingsActionTypes.CreateIntegrationCredentialsSuccess,
  props<{ channels: Channel[] }>()
);

export const createIntegrationCredentialsFailure = createAction(
  SettingsActionTypes.CreateIntegrationCredentialsFailure,
  props<any>()
);

/* -------- Organization Preferences -------- */
export const updateOrganizationPreferences = createAction(
  SettingsActionTypes.UpdateOrganizationPreferences,
  props<{
    requirePhoneConsent?: boolean;
    channel?: string;
    preferredTimezone?: string;
    enableEmailTrackingPixel?: boolean;
    enableLinkTracking?: boolean;
    whitelabel?: { email: Whitelabel['email'] };
  }>()
);

export const updateOrganizationPreferencesSuccess = createAction(
  SettingsActionTypes.UpdateOrganizationPreferencesSuccess,
  props<OrganizationSettings>()
);

/* -------- Organization whitelabel -------- */
export const loadOrganizationWhitelabel = createAction(
  SettingsActionTypes.LoadOrganizationWhitelabel,
  props<{ organizationId: string }>()
);

export const loadOrganizationWhitelabelSuccess = createAction(
  SettingsActionTypes.LoadOrganizationWhitelabelSuccess,
  props<Whitelabel>()
);

export const loadOrganizationWhitelabelFailure = createAction(
  SettingsActionTypes.LoadOrganizationWhitelabelFailure,
  props<any>()
);

/* -------- Domains -------- */
export const loadAuthenticatedDomains = createAction(
  SettingsActionTypes.LoadAuthenticatedDomains
);

export const loadAuthenticatedDomainsSuccess = createAction(
  SettingsActionTypes.LoadAuthenticatedDomainsSuccess,
  props<{ [key: string]: AuthenticateDomainResponse }>()
);

export const loadAuthenticatedDomainsFailure = createAction(
  SettingsActionTypes.LoadAuthenticatedDomainsFailure,
  props<any>()
);

export const addAuthenticatedDomain = createAction(
  SettingsActionTypes.AddAuthenticatedDomain,
  props<{ domain: string }>()
);

export const addAuthenticatedDomainSuccess = createAction(
  SettingsActionTypes.AddAuthenticatedDomainSuccess,
  props<{ domain: AuthenticateDomainResponse }>()
);

export const addAuthenticatedDomainFailure = createAction(
  SettingsActionTypes.AddAuthenticatedDomainFailure,
  props<any>()
);

export const deleteAuthenticatedDomain = createAction(
  SettingsActionTypes.DeleteAuthenticatedDomain,
  props<{ domain: string }>()
);

export const deleteAuthenticatedDomainSuccess = createAction(
  SettingsActionTypes.DeleteAuthenticatedDomainSuccess,
  props<{ domain: string }>()
);

export const deleteAuthenticatedDomainFailure = createAction(
  SettingsActionTypes.DeleteAuthenticatedDomainFailure,
  props<any>()
);

export const verifyAuthenticatedDomains = createAction(
  SettingsActionTypes.VerifyAuthenticatedDomain,
  props<{ domain: string; id: string }>()
);

export const verifyAuthenticatedDomainsSuccess = createAction(
  SettingsActionTypes.VerifyAuthenticatedDomainSuccess,
  props<{ domain: string; verification: VerifyDomainResponse }>()
);

export const verifyAuthenticatedDomainsFailure = createAction(
  SettingsActionTypes.VerifyAuthenticatedDomainFailure,
  props<any>()
);

export const sendDnsDetailsToCoworker = createAction(
  SettingsActionTypes.SendDnsDetailsToCoworker,
  props<{ domain: string; email: string; message: string }>()
);

export const sendDnsDetailsToCoworkerSuccess = createAction(
  SettingsActionTypes.SendDnsDetailsToCoworkerSuccess,
  props<any>()
);

export const sendDnsDetailsToCoworkerFailure = createAction(
  SettingsActionTypes.SendDnsDetailsToCoworkerFailure,
  props<any>()
);

export const saveOrgAttr = createAction(
  SettingsActionTypes.SaveOrgAttr,
  props<OrgAttr>()
);

export const saveRosterColumns = createAction(
  SettingsActionTypes.SaveRosterColumns,
  props<{ columns: string[] }>()
);
