<div class="grid-x align-center text-center">
  <div class="padding-m margin-bl cell">
    <zlo-icon name="zelo-logo" width="100px"></zlo-icon>
  </div>

  <div class="cell grass"></div>

  <div class="cell">
    <ng-container *ngIf="(hasCode$ | async) === false">
      <form [formGroup]="form" (submit)="submit()">
        <div class="login-form-container grid-x">
          <div class="cell">
            <h4 class="login-title">
              Login with SSO
            </h4>
          </div>

          <div class="cell text-left">
            <label>
              {{ 'auth.emailLabel' | translate }}
              <span
                class="error-list"
                *ngIf="isSubmitted && form.get('email').hasError('required')"
              >
                {{ 'auth.errors.isRequired' | translate }}</span
              >
              <span
                class="error-list"
                *ngIf="isSubmitted && form.get('email').hasError('email')"
              >
                {{ 'auth.errors.isInvalid' | translate }}</span
              >
              <input
                type="text"
                [placeholder]="'auth.emailPlaceholder' | translate"
                formControlName="email"
                autocomplete="email"
                autofocus="true"
              />
            </label>
          </div>
          <div class="cell">
            <button
              class="button small expanded login-button"
              type="submit"
              mat-button
            >
              {{ 'auth.login.button' | translate }}
            </button>
          </div>
        </div>
      </form>
    </ng-container>
    <ng-container
      *ngIf="(hasCode$ | async) && (errorState$ | async) !== 'Invalid code'"
    >
      <div class="login-form-container grid-x">
        <mat-spinner [diameter]="32" style="margin: auto;"></mat-spinner>
      </div>
    </ng-container>
    <ng-container *ngIf="errorState$ | async as error">
      <div class="login-form-container grid-x">
        <div class="cell">
          <h4 class="login-title">
            {{ error }}
          </h4>
          <button
            class="button small expanded login-button"
            (click)="resetSSO()"
            matButton
          >
            Try again
          </button>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="cell login-nav-link">
    <div class="grid-y">
      <div class="cell shrink">
        <a routerLink="/auth/login">
          {{ 'auth.login.header' | translate }} with password
        </a>
      </div>
      <div class="cell shrink">
        <a routerLink="/auth/endusers">
          {{ 'auth.links.sendOverview' | translate }}
        </a>
      </div>
      <div class="cell shrink margin-tm">
        <a href="https://www.zeloapp.com" target="_blank" rel="noopener">
          {{ 'auth.links.aboutZelo' | translate }}
        </a>
      </div>
    </div>
  </div>
</div>
